import axios from '@axios'

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchTasks(ctx, payload) {
            return new Promise((resolve, reject) => {
                console.log("payload",payload);
                axios
                    .post(`${process.env.VUE_APP_BASE_URL}/api/Calendar/todo`, {
                        pageSize: 1000,
                        currentPage: 1,
                        sortExpression: "beginDateUtc",
                        sortDirection: "asc",
                        searchText: payload.q,
                        includeDeletedRecords: payload.filter === "deleted" ? true : false, // ,important ,completed ,deleted
                        fromDate: "2020-04-15T07:36:55.802Z",
                        toDate: "2024-04-15T07:36:55.802Z",
                        isCompleted:payload.filter?  (payload.filter === "completed" ? true : false):false,
                        isImportant:payload.filter? (payload.filter === "important" ? true : null):null,
                        isDeleted: payload.filter === "deleted" ? true : false
                    })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        fetchUsers(ctx) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`${process.env.VUE_APP_BASE_URL}/api/User/simple-list`, {
                        pageSize: 2000,
                        currentPage: 1,
                        sortExpression: null,
                        includeDeletedRecords: false,
                    })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        addTask(ctx, taskData) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`${process.env.VUE_APP_BASE_URL}/api/Calendar/todo/create`, taskData)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        updateTask(ctx, { task }) {
            return new Promise((resolve, reject) => {
                axios
                    .put(`${process.env.VUE_APP_BASE_URL}/api/Calendar/todo`, task)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        removeTask(ctx, { id }) {
            return new Promise((resolve, reject) => {
                axios
                    .delete(`${process.env.VUE_APP_BASE_URL}/api/Calendar/todo/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
    },
}